<template>
  <div class="creation-step">
    <div v-if="!isEditing" class="step-heading">
      <h1 class="step-heading__title">Provide a professional summary</h1>
      <p class="step-heading__text">
        Tell employers why you're the best choice for the job.
      </p>
    </div>

    <div class="summary" :class="{ 'summary--creation': !isEditing }">
      <AppTextEditor
        v-if="resume"
        label="Summary"
        :value="resume.professional_summary"
        enablePrefilled
        :isInvalid="$v.professionalSummary.$error"
        @searchPrefilled="getPrefilled"
        @input="setSummary"
        :preFillDefaultValue="defaultJobTitle"
        preFillPlaceHolder="Search by Job Title"
        @openPrefilled="openPrefilled"
        @closePrefilled="closePrefilled"
        :isEditing="isEditing"
        isPrefillEditable="True"
      >
        <template v-slot:error>
          <p v-if="!$v.professionalSummary.minLength">
            Min number of characters
            {{ $v.professionalSummary.$params.minLength.min }}
          </p>

          <p v-if="!$v.professionalSummary.maxLength">
            Max number of characters
            {{ $v.professionalSummary.$params.maxLength.max }}
          </p>
        </template>
      </AppTextEditor>
    </div>

    <div v-if="!isEditing" class="creation-form-header">
      <button
        class="creation-form-header__preview"
        type="button"
        @click.prevent="openSidebar"
      >
        <AppIcon componentName="SearchIcon" />
      </button>
    </div>

    <FooterButton
      v-if="!isShowBackButton"
      :isEditing="isEditing"
      :ishideMobileFooter="hideMobileFooter"
      @goNextStep="submitStep"
    ></FooterButton>

    <FooterButtonv2
      v-if="isShowBackButton"
      :isEditing="isEditing"
      :ishideMobileFooter="hideMobileFooter"
      @goNextStep="submitStep"
    ></FooterButtonv2>
  </div>
</template>

<script>
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";

import { mapGetters } from "vuex";
import { eventBus } from "@/helpers/event-bus";
import { CREATING_RESUME_NAMES, ROUT_PATH } from "@/helpers/rout-constants";
import { resumeCreationMixin } from "@/mixins/ResumeCreationStepsMixin";
import AppTextEditor from "@/components/ui/AppTextEditor";
import { debounce } from "lodash/function";
import { setIntermediateResume } from "@/helpers/save-intermediate-data";
import { COMPONENT_NAMES } from "@/helpers/components-names";
import AppToast from "@/helpers/toast-methods";
import ResumeHash from "@/helpers/resume-hash";
import { mixpanelMixin } from "@/mixins/MixPanelMixin";
import CookieHelper from "@/helpers/cookie-helper";
import FooterButton from "@/components/creation/FooterButton";
import FooterButtonv2 from "@/components/creation/FooterButtonv2";

export default {
  name: COMPONENT_NAMES.RESUME_CREATION.SUMMARY,
  mixins: [resumeCreationMixin, mixpanelMixin],
  components: { AppTextEditor, FooterButton, FooterButtonv2 },

  provide() {
    const prefilled = {};

    Object.defineProperty(prefilled, "prefilledData", {
      enumerable: true,
      get: () => this.prefilledData,
    });

    return {
      prefilled,
    };
  },

  props: {
    isEditing: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    setFieldDebounced() {
      return debounce(async function () {
        if (this.isEditing) {
          this.$nextTick(() => {
            this.$parent.$emit("editData", this.professionalSummary);
          });
        }

        await this.$store.dispatch("resumeCreation/updateResume", {
          professional_summary: this.professionalSummary,
        });

        await setIntermediateResume(this.resume.hash);
      }, 500);
    },
    ...mapGetters({
      initialWorkHistories: "resumeCreation/getWorkHistory",
    }),
  },

  validations: {
    professionalSummary: {
      // minLength: minLength(100),
      // maxLength: maxLength(1000),
    },
  },

  data() {
    return {
      nextStepName: CREATING_RESUME_NAMES.STEP_FINAL,
      prevStepName: CREATING_RESUME_NAMES.STEP_4,
      professionalSummary: "",
      prefilledData: [],
      defaultJobTitle: "",
      hideMobileFooter: false,
      isShowBackButton: false,
    };
  },

  methods: {
    setSummary(summaryData) {
      this.professionalSummary = summaryData;

      this.setFieldDebounced();
    },

    async getPrefilled(querySearch) {
      try {
        const prefilled = await this.$store.dispatch(
          "resumeCreation/getResumeSummaryPrefilled",
          querySearch
        );

        this.$set(this, "prefilledData", prefilled);
      } catch (e) {
        console.error(e);
      }
    },

    async submitStep() {
      this.$v.$touch();

      if (this.$v.$invalid) {
        return;
      }

      await this.$store.dispatch("loader/pending");

      try {
        await this.$store.dispatch("resumeCreation/updateResume", {
          professional_summary: this.professionalSummary,
          is_draft: false,
        });

        await this.$store.dispatch("loader/pending");
        await this.$router.push({
          name: this.nextStepName,
          query: { uncompletedTemplate: this.resume.hash },
        });
      } catch (e) {
        console.error(e);
      }
      await this.$store.dispatch("loader/done");
    },

    checkValidation() {
      return true;
    },

    openPrefilled() {
      this.hideMobileFooter = true;

      this.mixpanelTrack("V2-SUGGESTEDDESC-PROFSUMMARY");
    },

    closePrefilled() {
      this.hideMobileFooter = false;
    },
  },

  beforeRouteEnter(to, from, next) {
    if (!ResumeHash.get()) {
      next(ROUT_PATH.BROWS_RESUME_TEMPLATES);
      AppToast.toastError(
        "No resume data found. Perhaps your resume has already been created. You can always view all your resumes in the dashboard"
      );
      return;
    }

    next();
  },

  async mounted() {
    let back_btn = CookieHelper.getFlag("back_btn", "");
    if (back_btn == "1") {
      this.isShowBackButton = true;
    }
  },

  async created() {
    let vm = this;
    await this.getInitialResume();

    this.mixpanelTrack("V2-PROFSUMMARY");

    if (this.resume !== null) {
      this.professionalSummary = this.resume.professional_summary;
    } else if (!this.resume && ResumeHash.get()) {
      this.resume = await this.$store.dispatch(
        "resumeCreation/getResume",
        ResumeHash.get()
      );
      this.professionalSummary = this.resume.professional_summary;
    } else {
      this.professionalSummary = [];
    }

    this.$parent.$emit("editData", this.professionalSummary || "");

    eventBus.$on("nextStepResumeCreation", this.submitStep);
    eventBus.$on("prevStepResumeCreation", this.goPrevStep);

    if (this.initialWorkHistories[0] != undefined) {
      this.defaultJobTitle = this.initialWorkHistories[0].job_title;
    }
  },

  beforeDestroy() {
    eventBus.$off("nextStepResumeCreation", this.submitStep);
    eventBus.$off("prevStepResumeCreation", this.goPrevStep);
  },
};
</script>

<style lang="scss" scoped>
@import "../../../assets/scss/modules/form.scss";
.summary {
  width: 100%;
}

::v-deep .summary--creation {
  .editor-wrap .quill-editor .ql-container .ql-editor {
    @media (min-width: map_get($grid-breakpoints, md)) {
      max-height: 250px;
    }

    @media (min-width: map_get($grid-breakpoints, xxl)) {
      max-height: 400px;
    }
  }
}

.creation-form-header__preview.order-md-2 {
  position: absolute;
  top: 0 !important;
  right: 9px;
  background: #fff;
  width: 70px;
  margin: 12px;
  padding: 2px;
  display: block;
  min-width: auto;
  z-index: 22;
  border: 2px solid #4874e6;
  border-radius: 30px;
}

button.creation-form-header__preview.order-md-2 svg {
  width: 17px;
  fill: #4874e6;
}
</style>
