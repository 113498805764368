import { mapGetters } from "vuex";

import { eventBus } from "@/helpers/event-bus";
import UncompletedCreationStep from "@/helpers/uncompleted-creation-step";
import { setIntermediateResume } from "@/helpers/save-intermediate-data";
import AppToast from "@/helpers/toast-methods";
import { CREATING_RESUME_NAMES } from "@/helpers/rout-constants";
export const resumeCreationMixin = {
  computed: {
    ...mapGetters({
      resume: "resumeCreation/getResume",
    }),
  },

  data() {
    return {
      switch: true,
    };
  },

  methods: {
    openSidebar() {
      eventBus.$emit("openCreationSidebar");
    },
    isFilled(activeForm) {
      let selectedLevel;
      if (activeForm.classList.contains("skill-form")) {
        selectedLevel = activeForm.querySelector(".level--selected");
      }
      const inputFields = activeForm.querySelectorAll("input");
      const isFilled = Array.from(inputFields).some((input) => {
        return input.value.length > 0;
      });
      return isFilled || selectedLevel;
    },

    submitForm() {
      this.$nextTick(() => {
        const activeForm = document.querySelector(
          ".experience-form.experience-form--active"
        );
        if (activeForm) {
          if (this.isFilled(activeForm)) {
            if (
              this.$route.name == CREATING_RESUME_NAMES.STEP_2 ||
              this.$route.name == CREATING_RESUME_NAMES.STEP_3 ||
              this.$route.name == CREATING_RESUME_NAMES.STEP_4
            ) {
              activeForm
                .querySelector(".experience-form-btns__item--submit")
                .click();
            }
          } else {
            activeForm
              .querySelector(".experience-form-btns__item--cancel")
              .click();
          }
        } else {
          this.switch = false;
          this.goNextStep();
        }
      });
    },

    async goNextStep() {
      if (this.switch) {
        this.submitForm();
        return;
      }
      if (this.checkValidation && !this.checkValidation() && !this.switch) {
        this.switch = true;
        await this.$store.dispatch("loader/done");
        AppToast.toastError("Fill all required fields, please!");
        return;
      }
      const inputs = document.querySelectorAll("input, textarea");
      for (let i = 0; i < inputs.length; i++) {
        inputs[i].blur();
      }
      const contentEditable = document.querySelectorAll(
        "[contenteditable='true']"
      );
      for (let i = 0; i < contentEditable.length; i++) {
        contentEditable[i].blur();
      }
      await this.$store.dispatch("loader/pending");
      UncompletedCreationStep.set(this.nextStepName);
      await this.$router.push({ name: this.nextStepName });
    },

    async getInitialResume(hash) {
      if (!this.resume && !this.isEditing) {
        await this.$store.dispatch("loader/pending");

        try {
          await this.$store.dispatch("resumeCreation/getResume", hash);
        } catch (e) {
          // console.error(e);
          return;
        }
      }

      if (!this.isEditing) {
        await setIntermediateResume(this.resume.hash);
      }

      await this.$store.dispatch("loader/done");
    },
  },

  async beforeMount() {
    if (!this.isEditing) {
      await this.$store.dispatch("loader/done");
    }
  },
};
